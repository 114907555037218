import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { chatUrl } from './utils/config'
import 'normalize.css'
import './style/index.scss'
import "./assets/iconfont/iconfont.css";
import { get, post } from './utils/request'
import {
  getLocalStorageStr, getCurLanguage, goGoodsDetail, goStoreDetail, checkMobile, checkEmailCode, checkPwd, checkSmsCode,
  checkMemberName, checkImgCode, addCart, diyNavTo, sldCommonTip, checkEmail, getUserInfo, cartDataNum, getLoginCartListData, calculateSubtotal, goGoodsListByCatId, sldLlineRtextAddGoodsAddMargin, getSldHorLine, formatChatTime, isShowTime, getQueryVariable
} from './utils/common';
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn';
import VueSocketIO from "vue-socket.io";

const app = createApp(App);

//组件引用 start，最后统一修改
// const components=[
//     ElButton,ElDialog
// ]
// components.forEach(component => {
//     app.component(component.name, component)
//   })
//组件引用 end
// 配置全局属性
app.config.globalProperties.$get = get;
app.config.globalProperties.$post = post;
app.config.globalProperties.$getLocalStorageStr = getLocalStorageStr;
app.config.globalProperties.$getCurLanguage = getCurLanguage;
app.config.globalProperties.$goGoodsDetail = goGoodsDetail;
app.config.globalProperties.$goStoreDetail = goStoreDetail;
app.config.globalProperties.$checkMobile = checkMobile;
app.config.globalProperties.$checkPwd = checkPwd;
app.config.globalProperties.$checkSmsCode = checkSmsCode;
app.config.globalProperties.$checkMemberName = checkMemberName;
app.config.globalProperties.$checkImgCode = checkImgCode;
app.config.globalProperties.$addCart = addCart;
app.config.globalProperties.$diyNavTo = diyNavTo;
app.config.globalProperties.$sldCommonTip = sldCommonTip;
app.config.globalProperties.$checkEmail = checkEmail;
app.config.globalProperties.$getUserInfo = getUserInfo;
app.config.globalProperties.$cartDataNum = cartDataNum;
app.config.globalProperties.$getLoginCartListData = getLoginCartListData;
app.config.globalProperties.$calculateSubtotal = calculateSubtotal;
app.config.globalProperties.$checkEmailCode = checkEmailCode;
app.config.globalProperties.$goGoodsListByCatId = goGoodsListByCatId;
app.config.globalProperties.$sldLlineRtextAddGoodsAddMargin = sldLlineRtextAddGoodsAddMargin;
app.config.globalProperties.$getSldHorLine = getSldHorLine;
app.config.globalProperties.$formatChatTime = formatChatTime;
app.config.globalProperties.$isShowTime = isShowTime;
app.use(ElementPlus, { locale });
app.use(new VueSocketIO({
  debug: true,
  connection: chatUrl,
}))


app.use(router).use(store).mount('#app')

localStorage.setItem('isStoreAdmin', getQueryVariable('isStoreAdmin'))
localStorage.setItem('vendorId', getQueryVariable('vendorId'))
localStorage.setItem('storeId', getQueryVariable('storeId'))
localStorage.setItem('sld_token', getQueryVariable('sld_token'))
router.beforeEach(() => {
  window.scrollTo(0, 0)
})


