/**
 *  项目的配置文件
 */

export const apiUrl = 'https://teyounongpin.com/'//接口请求地址
export const chatUrl = 'wss://im.teyounongpin.com'//客服地址
export const storeUrl = 'https://seller.teyounongpin.com/';//对应的商户后台地址
export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名

/** copyright *** slodon *** version-v3.3 *** date-2022-02-18 ***主版本v3.7**/
